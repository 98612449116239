
@media only screen and (min-width:0px) and (max-width:767px) {

    .mob_site_menu {
        background: url(../images/icon/menu_bg.jpg) no-repeat center center;
        background-position: 20px !important;
    }
    .mob_main_cart {
        background: url(../images/icon/mob_cart.jpg) no-repeat center center;
    }
    .mob_main_user {
        background: url(../images/icon/user.jpg) no-repeat center center;
    }

    .hide_small_screen{
        display: none;
    }
    .mob_menu_wrapper .site_menu_wrapper_inner{
        clear: both;
        width: 100%; 
    }
    .mob_site_menu, .mob_main_user, .mob_main_cart {
        width: 32.3%;
        height: 45px;
        display: inline-block;
        vertical-align: top;
        color: #FFF;
        text-align: center;
    }
    .headere_mobile .mob_site_menu {
        width: 50px;
        background: url('../images/public_images/menu.svg') no-repeat center;
    }

    .mob_main_user {
        border-right: 1px solid #868686;
        border-left: 1px solid #868686;
        border-top: 0px solid #868686;
        border-bottom: 0px solid #868686;
    }
    .mob_site_content {
        left: -800px;
        position: absolute;
        top: 0px;
        width: 260px;
        height: 500%;
        z-index: 9999;
        overflow-y: auto;
        background-image: url(../images/box-shadow-mobile.png);
        background-position: right 0px;
        background-repeat: repeat-y;
        background-color: rgb(255, 255, 255);
        transition: all 0.2s ease-in-out 0s;
    }
    
        
    .mob_main_user_wrapper {
        top: 44px;
        position: absolute;
        border: 1px solid rgba(0, 0, 0, .15);
        width: 100%;
        left: 0;
        text-align: left;
        background: #FFF;
        z-index: 1;
        padding: 10px 15px;
    }
    .mob_main_user_wrapper ul li {
        margin: 0 0 8px 0;
        color: #323232;
        list-style: none;
    }
    .mob_main_user_wrapper ul li a {
        color: #000;
        font-family: "Montserrat",sans-serif;
        font-size: 12px;
        font-weight: 400;
    }
    .mob_main_user_wrapper {
        display: none;
    }
    body.current .mob_site_content {
        left: 0px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
    body.current {
        left: 0;
        width: 100%;
        overflow: hidden;
        position: fixed;
        height: 100%;
    }
    .mob_site_content li a {
        font-size: 13px;
        font-weight: 700;
        color: #000;
        padding: 12px 20px;
        margin: 0;
        border-bottom: 1px solid var(--text_color);
        display: block;
        text-align: left;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
    }
    .mob_text_order {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #e7e7e7;
        padding: 9px 10px;
        color: #141414;
        margin: 0;
        line-height: 30px;
        letter-spacing: 0.02em;
        opacity: 0.7;
        font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
    }
    .mob_header_logo {
        border-bottom: 1px solid #e7e7e7;
        float: left;
        margin: 28px 0 20px;
        padding-bottom: 38px;
        text-align: center;
        width: 100%;
    }

    .mob_header_logo a img {
        width: 147px;
    }
    .site-header {
        margin: 0;
    }
    .user_login {
        width: 100%;
    }
    .user_login .inline_checkbox{
        margin: 10px 0 -5px 0;
    }
    .new_distributor_sign_up {
        padding: 0;
        margin: 12px 0 -7px 0;
        width: 100%;
    }
    .lost_password{
        margin: 0px;  
    }
    .footer-top {
        text-align: center;
    }
    .footer_contact {
        margin: 0 0 6px 0;
    }
    .site-footer h3 {
        margin: auto auto 10px auto;
    }
    .site-footer .widget {
        margin: 15px 0;
    }
    .footer-top ul.social, .footer-top .text {
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin: 0 6px;
    }
    .user_name_email_field input,
    .cus_button,
    .input_type_file input[type=file],
    .name_field,
    .user_registration {
        width: 100%;
    }
    .product-list-container {
        width: 100%;
    }
    .scrollup {
        padding: 0px 10px;
        right: 15px;
        top: 90%;
    }
    .cart_add_product_img {
        width: 80px;
        height: 80px;
    }
    .variant-changer {
        width: 100px;
    }
    .my_account_orders thead{
        display: none;
    }
    .shop_table_responsive tr td::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
    }
    td.cart_page_product_action::before,
    .shop_table_responsive tr td.cart_product_remove::before{
        display: none;
    }
    .shop_table_responsive tr td {
        display: block;
        text-align: right;
        border-width: 0px 0px 1px 0px;
    }
    .shop_table_responsive tr td:last-child {
        border-width: 1px 0px 0px 0px !important;
        padding: 15px;
    }
    .shop_table_responsive tr{
        display: block;
        margin-bottom: 20px;
    }
    .table_search {
        text-align: left;
    }
    .table_search select, .table_search input {
        display: inline-block;
        width: 100% !important;
        /* margin: 6px 0; */
    }
    .inner_content{
        margin-top: 20px;
    }
    .my_account_orders tr td .order-rsp-change {
        margin: 0 0 0 10px;
        float: none;
    }
    .page-title {
        font-size: 14px;
        margin: 0 0 10px 0;
    }
    .my_account_orders {
        margin-bottom: 15px;
    }
    .billing_address_container {
        width: 100%;
        margin: 30px 0 0 0;
    }
    .left_menu{
        margin: 0 0 20px 0;
        padding: 15px;
    }
    .get_in_touch .form-group input[type=text]{
        width:100%;
    }
    .my_account .col-md-6{
        padding: 0px;
        margin: 10px 0;
    }
    .action_form{
        padding: 15px;
    }
    .my_account .cus_button{
        width: auto;
    }
    .terms_and_condition h3 {
        font-size: 16px;
        margin: 0 0 3px 0;
    }
    .athletes_list img {
        width: 100%;
        height: auto;
    }
    .commission_aside, .order_aside {
        width: 100%;
        background: #f9f9f9;
        padding: 10px;
    }
    .page-content.entry-content p{
        line-height: 24px;
    }
    .shipping-address{
        font-size: 14px; 
    }
    .subscription_end_date_edit_calender input {
        width: 100%;
        margin: 0 0 4px 0;
    }
    .subscription_end_date_edit_calender a {
        display: block;
        margin: 0 0 4px 0;
    }
    .order-actions a {
        display: block;
        /*vertical-align: middle;*/
        margin: 4px 0px;
    }
    .date_by_day {
        width: 100%;
        margin: 0 0 15px 0;
        padding: 10px 0 0 0;
    }
    .table_search label {
        margin: 0 0 2px 0;
    }
    .page-title a{
        float: none;
        display: block;
    }
    .mob_left_right_none{
        float: none;
    }
    .word_break{
        word-break: break-all;
    }
    .mob_order_action_inline a{
        display: inline-block;
        margin: 0 10px;
    }
    .section_blog label {
        display: block;
    }
    .my_paypal_form .cus_field {
        width: 100%;
    }
    .label_block{
        display: block;
    }
    .cart_page_product_img{
        display: none;
    }
    .cart_table tr td.product-thumbnail {
        width: 100%;
    }
    .cart_product_number .decrement,
    .cart_product_number .qty,
    .cart_product_number .increment{
        display: inline-block;
        vertical-align: middle;
        float:none;
    }
    .cart_product_remove {
        width: 58px;
        border-width: 0 1px 1px 0 !important;
        text-align: center !important;
    }
    .cart_page_data_list{
        border: 1px solid  #e1e1e1;
    }
    .cart_page_product_coupon{
        float: none;
        margin-bottom: 10px;
    }
    .cart_page_product_coupon .cus_button{
        width: 40%;
    }
    .countinue_shop{
        float: none;
    }
    .cart_page_product_coupon .input-text, .countinue_shop .update_cart {
        float: left !important;
        margin: 0 !important;
        width: 47% !important;
    }
    .no_display_mobile{
        display: none !important;
    }
    .cart_totals {
        padding: 10px;
    }
    .cart_totals .shop_table_responsive tr {
        display: block;
        margin-bottom: 0px;
    }
    .shipping-methods{
        font-size: 13px;
    }
    .col2-set {
        width: 100%;
    }
    .woocommerce-checkout-payment {
        padding:10px;
        margin: 0 0 20px 0;
    }
    .payment_box {
        padding: 0px;
        margin: 10px 0;
    }
    .fieldset {
        padding: 5px 5px;
    }
    .checkout_coupon {
        float: none;
        background: #e8e8e8;
        padding: 10px;
        margin: 0 0 15px 0;
    }
    .checkout_coupon .input-coupon,
    .checkout_coupon .cus_button{
        display: inline-block;
        vertical-align: top;
        float: none !important;
        width: 47%;
    }
    .order_details_list li {
        width: 100%;
        display: block;
        overflow: hidden;
        border-style: dotted;
        border-width: 0px 0px 1px 0px;
        border-color: #d3ced2;
        margin: 0 0 5px 0;
        padding: 0 0 4px 0;
    }
    .order_details_list li strong {
        display: inline-block;
        /*float: right;*/
    }
    .woocommerce-notice.woocommerce-notice--success.woocommerce-thankyou-order-received{
        padding: 15px;
    }
    .receive_order_details{
        margin-bottom: 20px;
    }
    .receive_order_details thead tr th{
        padding: 0 10px;
    }
    .checkout-payment-card-opt img {
        width: 70%;
    }
    .error404Img img{
        width: 100%;
    }
}

@media only screen and (min-width:320px) and (max-width:1024px) {



}

@media only screen and (min-width:0px) and (max-width:767px) {

}

@media only screen and (min-width:0px) and (max-width:756px) {

}

@media only screen and (min-width:768px) and (max-width:1900px) {

}
/** office samsung tab **/
@media only screen and (min-width:768px) and (max-width:1024px) {
    .tab_hide {
        display: none;
    }
    .site-footer.refer_footer .row>* {
        padding-bottom: 30px;
    }
    .main_menu_blk ul li a img {
        position: relative;
        top: -2px;
    }
    body .login_container {
        min-height: calc(100vh - 147px);
    }
    .site_menu ul {
        margin: 0px 0px 0px 100px;
    }
    .entry-content .faq_container{
        padding: 10px;
    }
    .coupon-wrapper-container {
        width: 100%;
        margin-bottom: 0;
    }
    .error404Img img{
        width: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {

}


/** this css for ipad office **/


@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1)
and (orientation: portrait){
    .error404Img img{
        width: 100%;
    }
    .product-list-container {
        margin: auto auto 50px auto;
        width: 100%;
    }
    .my_account_orders tr td, .my_account_orders tr th, .receive_order_details tr td {
        padding: 5px;
        font-size: 13px;
    }
    .cart_product_details {
        margin: 0 5px;
        width: 176px;
    }
    .my_account_orders tr td a, .receive_order_details tr td a{
        font-size: 13px;
    }
    .cart_product_number input {
        padding: 5px;
        width: 50px;
        border-radius: 0px;
    }
    .increment, .decrement {
        background: #dddddd;
    }
    .cart_table .actions .input-text{
        border-radius: 0px;
    }
    .coupon.checkout_coupon{
        width: 100%;
        float: none;
        margin: 0 0 15px 0;
    }
    .woocommerce-checkout-payment {
        padding: 10px;
        margin: 0 0 20px 0;
    }
    .payment_box {
        padding: 8px;
        margin: 10px 0;
        border-radius: 0;
        background-color: #f6f6f6;
        color: inherit;
        font-size: 13px;
    }
    .fieldset {
        padding: 5px;
        margin: 0px;
    }
    .review-order-table-wrapper {
        margin: 0 0 20px 0;
    }
    .cart_totals {
        padding: 10px;
    }
    .left_menu {
        padding: 5px;
    }
    .page-title {
        font-size: 18px;
        margin-bottom:18px;
    }
    .table_search select, .table_search input {
        width: 23%;
    }

    .my_account_orders thead{
        display: none;
    }
    .shop_table_responsive tr td::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
    }

    /*.shop_table_responsive tr td.order-representative::before,
    .shop_table_responsive tr td.order-total::before{

    }*/
    .order-actions::before,
    td.cart_page_product_action::before,
    .shop_table_responsive tr td.cart_product_remove::before{
        display: none;
    }
    .shop_table_responsive tr td {
        display: block;
        text-align: right;
        border-width: 0px 0px 1px 0px;
    }
    .shop_table_responsive tr td:last-child {
        border-width: 1px 0px 0px 0px !important;
        padding: 15px;
    }
    .shop_table_responsive tr{
        display: block;
        margin-bottom: 20px;
    }
    .my_account_orders tr td .order-rsp-change {
        float: none;
    }

    .table_search select, .table_search input {
        width: 40%;
        float: left;
    }
    .to_hide span.member_edit_cancel{
        width: auto;
    }
    .table_search_form_group label {
        display: block;
        text-align: left;
    }
    .date_by_day {
        width: 64%;
        float: left;
    }
    .shop_table_responsive .order-number a{
        float: none;
    }
}

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) 
and (orientation: landscape){

}
/** //EnD this css for ipad office **/



/**** css changes on 17/03/2023 **********/
@media only screen and (max-width: 767px){
    .modal-dialog {
        margin: 20px auto;
        width: 90% !important;
    }
    .d-flex {
        flex-wrap: wrap;
    }
    .customer_menu li {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 15px;
    }
    .customer_menu li a {
        display: flex;
        justify-content: center;
    }
    .sub_btm_det {
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
    }
    .order_pro_detail>* {
        width: 100% !important;
    }
    .order_pro_detail>*:not(:last-child) {
       margin-bottom: 10px;
    }
    .order_on_div, .order_status, body .w-50 {
        width: 100% !important;
    }
    body .order_accordatio {
        padding: 20px;
    }
    .order_status.ml_20 {
        justify-content: center;
        width: fit-content !important;
    }
    .rgt_acc {
        width: 100% !important;
        margin-top: 10px;
    }
    .rgt_acc a {
        margin-bottom: 17px;
    }
    .order_det_blk {
        margin-top: 20px;
        padding: 20px;
    }
    .billing_blk {
        margin-bottom: 25px;
    }
    .pay_top {
        margin-top: 25px;
    }
    .subscription_ul {
        width: 1140px;
    }
    body .sub_current_div {
        padding: 20px;
    }
    .sub_current_div>*>* {
        width: 100% !important;
    }
    .billing_blk.sub_billing_blk.mb_25 {
        margin-top: 25px;
    }
    .sub_current_div .col-md-4 {
        justify-content: flex-start !important;
        margin-top: 16px;
    }
    .sub_current_div>*>*.buy_now {
        width: fit-content !important;
    }
    body .add_new_card {
        padding: 20px;
    }
    .form_input_blk  form {
        padding: 0;
    }
    .add_new_btn {
        width: 100%;
    }
    .form-control>input {
        width: 50% !important;
    }
    body .refer_banner_slide {
        padding-left: 15px;
        padding-right: 15px;
    }
    body .banner_heading {
        font-size: 35px;
        width: 100%;
        line-height: 46px;
        margin-bottom: 20px;
    }
    .mr_36 {
        margin-right: 15px !important;
    }
    body .buy_now, body .view_detail {
        padding: 13.5px 20px;
    }
    body .font_41 {
        font-size: 24px;
        line-height: 35px;
    }
    body .best_selling_para {
        width: 100%;
    }
    body .best_selling_section, body .explore_product_section, body .shop_goal_section, body .explore_product_section.testimonial_section,
    body section.your_fitness_section {
        padding-left: 0;
        padding-right: 0;
    }
    body .exp_col_col {
        width: 100%;
        margin-bottom: 20px;
    }
    .goal_row.row>* {
        padding-bottom: 13px;
    }
    body .testsimonial_col {
        padding: 20px;
    }
    body .testsimonial_col {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .testi_left {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
    .testi_right {
        padding: 20px 0 0 0;
    }
    body .fitness_left, body .fitness_rgt, body .fitness_left img, body .our_story_left, .our_story_left img {
        width: 100%;
    }
    body .fitness_text {
        font-size: 35px;
        line-height: 46px;
        margin-top: 25px;
    }
    body .our_story_section{
        padding-left: 15px;
        padding-right: 15px;
    }
    .play_icon{
        width: 50px !important;
    }
    body .our_story_right {
        width: 100%;
        padding: 20px;
    }
    body .site-footer.refer_footer {
        padding: 30px 0px;
    }
    .location_blk span {
        width: calc(100% - 13px);
    }
    body .site-footer.refer_footer .row>*:not(:last-child) {
        padding-bottom: 30px;
    }
    body .pro_left_blk {
        padding: 30px 15px;
    }
    nav[aria-label="breadcrumb"] + img {
        margin-top: 20px;
    }
    body .pro_rgt_blk {
        padding: 30px 15px;
    }
    body .quan_cart_blk .cart_add_product_btn {
        width: calc(100% - 140px);
    }
    .del_pri {
        width: 30%;
    }
    .del_det {
        width: 70%;
        padding-left: 10px;
    }
    .dis_span.ml_16 {
        margin: 10px 0 !important;
    }
    body .product_tabs li.nav-item {
        margin: 0 0 10px 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    body .product_tabs li.nav-item>* {
        margin:  0;
    }
    body .ben_letf,  body .ben_letf img, body .howuse_heading, body .faq_section .container,
    body .like_pro_para, .wri_review_blk >* {
        width: 100% !important;
    }
    body .ben_rgt {
        width: 100%;
        padding: 20px 0 0 0;
    }
    .benefit_ul li span {
        padding-left: 15px;
        width: calc(100% - 24px);
    }
    .benefits_blk {
        padding-bottom: 30px;
    }
    .how_use_row>* {
        padding-bottom: 30px;
    }
    .faq_section .accordion-header .accordion-button {
        position: relative;
    }
    .faq_section .accordion-header .accordion-button img {
        position: absolute;
        right: 15px;
    }
    body .wri_review_blk {
        padding: 20px;
    }
    .rev_rgt  {
        padding-top: 20px;
    }
    .cart_pro_list .cart_pro_list_left {
        width: 100%;
        padding-bottom: 15px;
    }
    .cart_pro_list .cart_total {
        width: 100%;
    }
    .cart_rgt_blk {
        margin-top: 30px;
    }
    body .coupon_input {
        width: calc(100% - 82px);
    }
    .checkout_left_blk {
        padding: 30px 15px;
    }
    .checkout_row  .shop_more.take_quiz_btn {
        margin-top: 20px;
    }
    .checkout_ul li .pl_10 {
        padding: 10px 0 0 0;
    }
    .checkout_ul li {
        flex-direction: column;
    }
    .checkout_row>* {
        padding-bottom: 16px;
    }
    body .checkout_rgt_blk {
        padding: 30px 15px;
    }
    .shipping_left>*, body .order_confirmed_page .container {
        width: 100%;
    }
    .shipping_left>*.pl_6 {
        padding-top: 6px;
        padding-left: 0;
    }
    .upi_row.row>* {
        padding-bottom: 20px;
    }
    .checkbox_label .checbox + span {
        width: calc(100% - 14px);
    }
    body .thank_you_header, body .thank_you_body, body .thank_btn {
        padding: 20px;
    }
    ul.pro_cart_det.payemnt_total_pri {
        margin-top: 20px;
    }
    .order_no_date span {
        margin-bottom: 10px;
        text-align: left !important;
    }
    .thank_btn>*>.col-md-8 {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
    }
    .active_cancel_mob_sub>* {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }
    .active_cancel_mob_sub>*.mr_36 {
        margin-right: 0 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .main_menu_blk {
        display: none;
    }
    .banner_section .banner_right_img {
        width: 100% !important;
        height: inherit;
    }
    body .banner_heading {
        font-size: 41px;
        line-height: 53px;
        margin-bottom: 35px;
        width: 100% !important;
    }
    body .buy_now, body .view_detail {
        padding: 13px 20px;
    }
    .best_selling_pro .col-md-3, .goal_row  .col-md-4 {
        width: 50%;
        flex: inherit;
        max-width: inherit;
    }
    .goal_row  .col-md-4 {
        padding-bottom: 18px;
    }
    body .explore_img {
        width: 100%;
        height: 100%;
    }
    body .exp_col_col {
        width: 100%;
        margin: 0 auto;
    }
    .exp_col_col  h3 {
        text-align: center;
    }
    .header_blk .hide_small_screen .container-fluid, body .refer_banner_slide,body .explore_product_section, body .shop_goal_section,
    body .explore_product_section.testimonial_section, body section.your_fitness_section, body .site-footer.refer_footer {
        padding-left: 20px;
        padding-right: 20px;
    }
    .Testimonial_row>* {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
    body .fitness_left {
        width: 30%;
    }
    body .fitness_left img, body .our_story_left img, body .our_story_left, body .our_story_right {
        width: 100%;
    }
    body .fitness_rgt {
        width: 70%;
    }
    body .fitness_text {
        font-size: 41px;
        line-height: 46px;
    }
    .our_story_section>.d-flex {
        flex-wrap: wrap;
    }
    .play_icon {
        width: 50px !important;
    }
    .site-footer.refer_footer .row>.col-md-4 {
        width: 66%;
        flex: inherit;
        max-width: inherit;
        padding-bottom: 30px;
    }
    .site-footer.refer_footer .row>.col-md-2 {
        width: 33.33%;
        flex: inherit;
        max-width: inherit;
    }
    .site-footer.refer_footer .row>.col-md-4 + * {
        display: none;
    }
    .pro_left_blk.col-md-6, .pro_rgt_blk.col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 20px;
    }
    body .product_tabs>li .nav-link {
        margin-right: 20px;
    }
    body .ben_letf {
        width: 30%;
    }
    body .ben_letf img, body .howuse_heading {
        width: 100%;
    }
    body .ben_rgt {
        width: 70%;
        padding-left: 15px;
    }
    .how_use_row>* {
        flex: inherit;
        max-width: inherit;
        width: 50%;
        padding-bottom: 30px;
    }
    body .faq_section .container {
        width: 720px;
    }
    body .rev_left {
        width: 40%;
    }
    body .checkout_left_blk, body .checkout_rgt_blk {
        max-width: inherit;
        flex: inherit;
        width: 100%;
        padding: 30px 20px;
    }
    .dashboard_row>.col-md-4 {
        max-width: inherit;
        flex: inherit;
        width: 50%;
    }
    .order_acc_header {
        flex-wrap: wrap;
    }
    .order_acc_header>* {
        width: 50%;
    }
    .order_acc_header .order_status {
        width: fit-content;
    }
    .order_det_blk>.row>* {
        max-width: inherit;
        flex: inherit;
        width: 100%;
        padding-bottom: 20px;
    }
    .subscription_ul {
        width: 1140px;
    }
    .sub_current_div .d-flex {
        flex-wrap: wrap;
    }
    .sub_btm_det {
        margin-top: 10px;
    }
    .MyAccount-content .col-md-6 {
        max-width: inherit;
        flex: inherit;
        width: 100%;
        padding-bottom: 20px;
    }
    .account_row >* {
        width: 50%;
        flex: inherit;
        max-width: inherit;
    }
    .add_new_card.form_input_blk .form-control input {
        width: 50%;
    }
    .card_row.row>* {
        max-width: inherit;
        flex: inherit;
        width: 50%;
    }
}
/**** css changes on 17/03/2023 **********/

/**** css changes on 19/03/2023 **********/
@media only screen and (max-width: 991px) {
    .cus_dash_row  .cus_dah_left {
        width: 100%;
        padding-bottom: 30px;
    }
    .cus_dash_row  .cus_dash_rgt {
        width: 100%;
        padding-left: 15px;
    }
}
/**** css changes on 19/03/2023 - Ends **********/

/**** css changes on 19/03/2023 **********/
@media only screen and (max-width: 767px) {
    .add_item_blk .order_pro_btn .quan_cart_blk .cart_add_product_btn {
    width: 100%;
    margin: 0;
}
    .product_search_input {
        width: 100%;
        margin-top: 15px;
    }
    body .order_pro_det {
        width: 100%;
        padding: 16px 0 0 0;
    }
    body .order_pro_img {
        margin: 0 auto;
    }
    body .subscri_blk.choose_fla_blk {
        width: 100%;
    }
    body .subscri_blk {
        width: 100%;
        margin: 0 0 20px 0 !important;
    }
    body .order_container {
        padding: 45px 5px;
    }
    body .login_container {
        min-height: inherit;
    }
    body .pro_filter_sec {
        width: calc(100% - 30px);
    }
    .pro_filter_sec>*:first-child, .pro_filter_sec>*:nth-child(2) {
        margin-bottom: 10px;
    }
    .header_blk .mob_header_logo {
        border: none;
        margin: 0;
        float: none;
        padding: 20px;
    }
    .headere_mobile {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 15px 15px 0;
        align-items: center;
    }
    .headere_mobile .header_right_blk>ul>li {
        margin: 0 0 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #fff;
    }
    .headere_mobile .header_right_blk {
        width: calc(100% - 50px);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    body .main_menu_blk ul li a {
        padding: 12px 20px;
        margin: 0;
        line-height: 24px;
        font-size: 16px;
        text-align: left;
        text-transform: uppercase;
    }
    body .main_menu_blk ul li a.shop_all_link {
        font-size: 14px;
        line-height: 17px;
    }
    .headere_mobile .mob_site_content {
        width: 100%;
        overflow: auto;
        height: 100%;
    }
    .menu_close {
        padding: 20px;
        pointer-events: none;
    }
    .headere_mobile .mob_site_content li a {
        justify-content: space-between;
    }
    .headere_mobile .header_sub_menu .dropdown-menu {
        position: static !important;
        box-shadow: none;
        display: block;
    }
    .headere_mobile .shop_menurow>* {
        width: 50% !important;
    }
    .headere_mobile .shop_menurow>* .shop_menu_li {
        border: none;
    }
    .headere_mobile .shop_menurow {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
    }
    .headere_mobile .shop_menurow>* {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
    }
    .headere_mobile .main_menu_blk ul li:not(:last-child) {
        margin-right: 0;
    }
}

@media only screen and (max-width: 1024px) and (min-width:768px) {
    .mob_menu_wrapper.d-md-none {
        display: block !important;
    }
    .mob_menu_wrapper.d-md-none .mob_header_logo {
        display: none;
    }
    .mob_menu_wrapper.d-md-none .mob_site_content {
        display: flex;
        justify-content: center;
    }
    .headere_mobile .header_right_blk {
        display: none;
    }
    .menu_close {
        display: none !important;
    }
    .header_blk {
        display: flex;
        flex-direction: column-reverse;
    }
    .mob_menu_wrapper.d-md-none {
        display: block !important;
        padding: 15px 0;
        border-top: 1px solid #fff;
    }
    .mob_menu_wrapper.d-md-none .main_menu_blk {
        display: block;
    }
    .shop_menurow .w-20.col {
        width: 33.33% !important;
    }
    body .header_sub_menu .dropdown-menu {
        top: 144px !important;
    }
    .login_li{
        display: none !important;
    }
    .cart_row.row>* {
        width: 100%;
        flex: inherit;
        max-width: inherit;
        padding-bottom: 34px;
    }
}
/**** css changes on 19/03/2023 - Ends **********/

/**** css changes on 24/03/2023 **********/
@media only screen and (max-width: 767px) {
    .error404Img {
        padding-top: 30px;
    }
    .error404 {
        padding-bottom: 30px;
    }  
    body .login_container .user_login {
        width: 100%;
    }
    body .login_container .add_new_card .checkbox_label {
        margin: 0 !important;
        width: 50% !important;
    }
    body .customer_dash_full_view .cus_dash_rgt {
        padding: 40px 15px;
    }
    .subscribtion_table tr td:not(:first-child) {
        white-space: nowrap;
    }
    .subscribtion_table tr td:first-child .left_pro_de {
        width: 200px !important;
    }
    body .pro_carousel.browsing_his_carousel {
        width: 100%;
    }
    .subscribtion_table tr td:first-child .left_pro_de {
        width: 200px !important;
    }
    .table_search_select {
        width: 100%;
        justify-content: flex-end;
        margin-top: 20px;
    }
    body .order_det_blk {
        padding: 20px;
    }
    body .w-22 {
        width: 100% !important;
    }
    .sub_btm_det li {
        margin: 0 0 10px 0 !important;
        width: 100%;
    }
    body .add_new_btn {
        width: 100%;
    }
    .subscription_end_date_edit_calender >*>* {
        width: 100%;
        display: flex !important;
        justify-content: center;
        margin-bottom: 10px !important;
    }
    .contact_container .account_row>* {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
    body .contact_cppatcha .form-control {
        width: calc(100% - 170px);
        margin: 0 0  0 20px;
    }
    button#refresh {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .cus_dash_rgt .sub_current_div>*:first-child>* {
        width: 50%;
        flex:inherit;
        max-width: inherit;
    }
    body .sub_btm_det {
        margin-top: 20px;
        width: 100%;
        justify-content: flex-end;
    }
    .sub_btm_det li {
        margin: 0 0 0 10px!important;
    }
    .subscription_end_date_edit_calender>*>* {
        width: 30% !important;
        margin: 0 !important;
        justify-content: center;
        display: flex !important;
    }
    .contact_container .account_row>* {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
}
/**** css changes on 24/03/2023 - Ends **********/

/**** css changes on 24/03/2023  **********/
@media only screen and (max-width: 767px) {
    .table_search_select label {
        width: 100% !important;
        margin-bottom: 6px !important;
    }
    .table_search_select.justify-content-end {
        justify-content: flex-start !important;
    }
    body .table_search_select .form-control {
        margin: 0 10px 0 0 !important;
    }
    .order_acc_footer>*:last-child {
        width: 100%;
    }
    .mt_20 {
        margin-top: 20px !important;
    }
    .subscribtion_table th, .subscribtion_table td {
        white-space: nowrap;
    }
    .sub_current_div .text-right {
        text-align: left !important;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .sub_current_div {
        padding: 23px !important;
    }
}
/**** css changes on 24/03/2023 - Ends **********/

/**** css changes refer on 12/07/2023 **********/
@media only screen and (max-width: 767px) {
     /*** css changes on 03/07/2024 ***/
     body .header_search_blk {
        width: calc(100% - 15px);
        margin: 10px 0 0 15px;
    }
    .endless_section_blk h2 {
        margin-bottom: 9px !important;
    }
    .mobile_logo_blk  img {
        width: 150px;
    }
    /*** css changes on 03/07/2024 Ends ***/
    .mob_site_content li.header_sub_menu a {
        border: none;
    }
    .headere_mobile .header_sub_menu .dropdown-menu {
        border-bottom: 1px solid var(--text_color)  !important;
        padding-top: 15px;
    }
    .mob_site_content li a {
        display: flex;
    }
    .mob_site_content li.header_sub_menu a.shop_all_link {
        border-bottom: 1px solid var(--text_color);
        border-top: 1px solid var(--text_color);
    }
}
@media only screen and (max-width: 1400px) and (min-width: 1100px) {
    body .sub_btm_det li:not(:last-child) {
        margin-right: 15px;
    }
    body .active_cancel_mob_sub .mr_36 {
        margin-right: 15px !important;
    }
}
/**** css changes refer on 12/07/2023 - Ends **********/
